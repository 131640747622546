import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n.js";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  // root: {
  // 	display: "flex",
  // 	alignItems: "center",
  // 	padding: theme.spacing(8, 8, 3),
  // },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },

  tabs: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
}));

const SecurityTab = ({ settings, user }) => {
  const classes = useStyles();

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeSettingHyperMask = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    const relatedSettingKey =
      settingKey === "disableHyperlink" ? "maskLink" : "disableHyperlink";
    const relatedSelectedValue =
      selectedValue === "enabled" ? "disabled" : "enabled";

    try {
      await api.put(`/settings/${settingKey}`, { value: selectedValue });
      await api.put(`/settings/${relatedSettingKey}`, {
        value: relatedSelectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = (key) => {
    const { value } =
      settings && settings.length > 0 && settings.find((s) => s.key === key);
    return value;
  };

  return (
    <Can
      role={user.profile}
      perform="settings:edit"
      yes={() => (
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Can
              role={user.profile}
              perform="settings:edit"
              yes={() => (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body1">
                    {i18n.t("settings.settings.linkProtectionEnabled.name")}
                  </Typography>
                  <Select
                    margin="dense"
                    variant="outlined"
                    native
                    id="linkProtectionEnabled-setting"
                    name="linkProtectionEnabled"
                    value={
                      settings &&
                      settings.length > 0 &&
                      getSettingValue("linkProtectionEnabled")
                    }
                    className={classes.settingOption}
                    onChange={handleChangeSetting}
                  >
                    <option value="enabled">
                      {i18n.t(
                        "settings.settings.linkProtectionEnabled.options.enabled"
                      )}
                    </option>
                    <option value="disabled">
                      {i18n.t(
                        "settings.settings.linkProtectionEnabled.options.disabled"
                      )}
                    </option>
                  </Select>
                </Grid>
              )}
            />

            {settings &&
              settings.length > 0 &&
              getSettingValue("linkProtectionEnabled") === "enabled" && (
                <>
                  <Can
                    role={user.profile}
                    perform="settings:edit"
                    yes={() => (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">
                          {i18n.t("settings.settings.disableHyperlink.name")}
                        </Typography>
                        <Select
                          margin="dense"
                          variant="outlined"
                          native
                          id="disableHyperlink-setting"
                          name="disableHyperlink"
                          value={
                            settings &&
                            settings.length > 0 &&
                            getSettingValue("disableHyperlink")
                          }
                          className={classes.settingOption}
                          onChange={handleChangeSettingHyperMask}
                        >
                          <option value="enabled">
                            {i18n.t(
                              "settings.settings.disableHyperlink.options.enabled"
                            )}
                          </option>
                          <option value="disabled">
                            {i18n.t(
                              "settings.settings.disableHyperlink.options.disabled"
                            )}
                          </option>
                        </Select>
                      </Grid>
                    )}
                  />

                  <Can
                    role={user.profile}
                    perform="settings:edit"
                    yes={() => (
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="body1">
                          {i18n.t("settings.settings.maskLink.name")}
                        </Typography>
                        <Select
                          margin="dense"
                          variant="outlined"
                          native
                          id="maskLink-setting"
                          name="maskLink"
                          value={
                            settings &&
                            settings.length > 0 &&
                            getSettingValue("maskLink")
                          }
                          className={classes.settingOption}
                          onChange={handleChangeSettingHyperMask}
                        >
                          <option value="enabled">
                            {i18n.t(
                              "settings.settings.maskLink.options.enabled"
                            )}
                          </option>
                          <option value="disabled">
                            {i18n.t(
                              "settings.settings.maskLink.options.disabled"
                            )}
                          </option>
                        </Select>
                      </Grid>
                    )}
                  />
                </>
              )}

            <Can
              role={user.profile}
              perform={"settings:edit"}
              yes={() => (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body1">
                    {i18n.t(
                      "settings.settings.attachmentProtectionEnabled.name"
                    )}
                  </Typography>
                  <Select
                    margin="dense"
                    variant="outlined"
                    native
                    id="attachmentProtectionEnabled-setting"
                    name="attachmentProtectionEnabled"
                    value={
                      settings &&
                      settings.length > 0 &&
                      getSettingValue("attachmentProtectionEnabled")
                    }
                    className={classes.settingOption}
                    onChange={handleChangeSetting}
                  >
                    <option value="enabled">
                      {i18n.t(
                        "settings.settings.attachmentProtectionEnabled.options.enabled"
                      )}
                    </option>
                    <option value="disabled">
                      {i18n.t(
                        "settings.settings.attachmentProtectionEnabled.options.disabled"
                      )}
                    </option>
                  </Select>
                </Grid>
              )}
            />
          </Grid>
        </Paper>
      )}
    />
  );
};

export default SecurityTab;
