import React, { useState } from 'react';
import { 
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SyncRounded } from '@material-ui/icons';
import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { toast } from 'react-toastify';
import ConnectionSelect from '../ConnectionSelect';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const LabelSyncModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [connectionId, setConnectionId] = useState("");
  const [loadingSyncLabels, setLoadingSyncLabels] = useState(false);

  const handleConnectionChange = (value) => {
    setConnectionId(value);
  };

  const handleSyncLabels = async () => {
    setLoadingSyncLabels(true);
    try {
      await api.get("/labels/sync/" + connectionId);
      toast.success(i18n.t("labels.toasts.labelsSynced"));
      onClose();
    } catch (err) {
      toastError(err);
    }
    setLoadingSyncLabels(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{i18n.t("labels.buttons.sync")}</DialogTitle>
      <DialogContent>
        <p>{i18n.t("labels.syncMessage")}</p>
        <Box display="flex" flexDirection="column" style={{ gap: "1rem" }}>
          <Paper variant="outlined" className={classes.body}>
            <ConnectionSelect 
              onChange={handleConnectionChange} 
              channel="whatsappUnofficial" 
            />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {i18n.t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SyncRounded />}
          onClick={handleSyncLabels}
          disabled={loadingSyncLabels || !connectionId}
        >
          {loadingSyncLabels
            ? i18n.t("labels.buttons.syncing")
            : i18n.t("labels.buttons.sync")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LabelSyncModal;
