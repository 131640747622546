import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const HolidaySchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  date: Yup.date().required("Required"),
  greetingMessage: Yup.string().optional(),
});

const HolidayModal = ({ open, handleClose, holidayId }) => {
  const classes = useStyles();

  const [holiday, setHoliday] = useState({
    name: "",
    date: new Date(),
    greetingMessage: "",
  });

  useEffect(() => {
    if (holidayId) {
      api.get(`/holidays/${holidayId}`).then((response) => {
        setHoliday(response.data);
      });
    }
  }, [holidayId]);

  const handleSaveHoliday = (values) => {
    if (holidayId) {
      api.put(`/holidays/${holidayId}`, values).then((response) => {
        handleClose();
        toast.success(i18n.t("holidayModal.success.edit"));
      });
    } else {
      api.post(`/holidays`, values).then((response) => {
        handleClose();
        toast.success(i18n.t("holidayModal.success.add"));
      });
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          {holidayId
            ? `${i18n.t("holidayModal.title.edit")}`
            : `${i18n.t("holidayModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={holiday}
          enableReinitialize={true}
          validationSchema={HolidaySchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveHoliday(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      as={TextField}
                      variant="outlined"
                      margin="dense"
                      label={i18n.t("holidayModal.form.name")}
                      className={classes.textField}
                      fullWidth
                      helperText={touched.name ? errors.name : ""}
                      error={touched.name && Boolean(errors.name)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="date"
                      as={TextField}
                      variant="outlined"
                      margin="dense"
                      type="date"
                      fullWidth
                      label={i18n.t("holidayModal.form.date")}
                      className={classes.textField}
                      helperText={touched.date ? errors.date : ""}
                      error={touched.date && Boolean(errors.date)}
                      value={typeof values.date === 'object' ? values.date.toISOString().split("T")[0] : values.date.split("T")[0]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="greetingMessage"
                      as={TextField}
                      variant="outlined"
                      label={i18n.t("holidayModal.form.greetingMessage")}
                      className={classes.textField}
                      multiline
                      rows={10}
                      fullWidth
                      helperText={
                        touched.greetingMessage ? errors.greetingMessage : ""
                      }
                      error={
                        touched.greetingMessage &&
                        Boolean(errors.greetingMessage)
                      }
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("holidayModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {holidayId
                    ? `${i18n.t("holidayModal.buttons.okEdit")}`
                    : `${i18n.t("holidayModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default HolidayModal;
