import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import BusinessHoursModal from "../BusinessHoursModal";
import HolidayModal from "../HolidayModal";
import { parseISO, format } from "date-fns";
import { DeleteOutline, Edit } from "@material-ui/icons";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
  },
}));

const BusinessHoursTab = () => {
  const [businessHours, setBusinessHours] = useState([]);
  const [loading, setLoading] = useState(true);
  const daysMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };
  const [openModal, setOpenModal] = useState(false);
  const [openModalHoliday, setOpenModalHoliday] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [modalBusinessHoursId, setModalBusinessHoursId] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [holidaysId, setHolidaysId] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const debouncedFetch = setTimeout(async () => {
      const { data } = await api.get(`/business-hours`);
      setBusinessHours(data);

      const { data: holidays } = await api.get(`/holidays`);
      setHolidays(holidays);

      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(debouncedFetch);
    };
  }, [openModal, openModalHoliday, openModalConfirm]);

  const processTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  };

  const handleEdit = (businessHoursId) => {
    setOpenModal(true);
    setModalBusinessHoursId(businessHoursId);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalBusinessHoursId(null);
  };

  const handleEditHoliday = (holiday) => {
    setOpenModalHoliday(true);
    setHolidaysId(holiday.id);
  };

  const handleCloseModalHoliday = () => {
    setOpenModalHoliday(false);
    setHolidaysId(null);
  };

  const handleDelete = async (businessHoursId) => {
    try {
      await api.delete(`/business-hours/${businessHoursId}`);
    } catch (error) {
      toastError(error);
    }
  };

  const handleDeleteHoliday = async () => {
    try {
      await api.delete(`/holidays/${holidaysId}`);
    } catch (error) {
      toastError(error);
    }
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
    setHolidaysId(null);
  };

  const handleOpenModalConfirm = (holidayId) => {
    setOpenModalConfirm(true);
    setHolidaysId(holidayId);
  };

  return (
    <Paper className={classes.paper}>
      <BusinessHoursModal
        businessHoursId={modalBusinessHoursId}
        onClose={handleCloseModal}
        open={openModal}
      />
      <Grid container spacing={2} alignItems="center" alignContent="center">
        {loading ? (
          <Typography>{i18n.t("businessHours.loading")}</Typography>
        ) : (
          businessHours &&
          businessHours.length > 0 &&
          businessHours.map(
            (
              { id, dayOfWeek, startTime, endTime, is24Hours, isClosed },
              index
            ) => (
              <React.Fragment key={index}>
                <Grid item xs={4}>
                  <Typography variant="body1">{daysMap[dayOfWeek]}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="body1">
                    {isClosed
                      ? i18n.t("businessHours.closed")
                      : is24Hours
                      ? i18n.t("businessHours.allDays")
                      : `${processTime(startTime)} - ${processTime(endTime)}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(id)}
                  >
                    {i18n.t("businessHours.edit")}
                  </Button>
                </Grid>
              </React.Fragment>
            )
          )
        )}
      </Grid>

      <Divider style={{ marginTop: 22, marginBottom: 12, width: "100%" }} />

      <HolidayModal
        open={openModalHoliday}
        holidayId={holidaysId}
        handleClose={handleCloseModalHoliday}
      />
      <ConfirmationModal
        open={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleDeleteHoliday}
        title={i18n.t("businessHours.holiday.remove")}
        children={i18n.t("businessHours.holiday.confirmation")}
      />
      <Grid container spacing={2} alignItems="center" alignContent="center">
        <Grid item xs={12}>
          <Typography variant="h6">
            {i18n.t("businessHours.holiday.title")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => setOpenModalHoliday(true)}
          >
            {i18n.t("businessHours.holiday.add")}
          </Button>

          <Table
            size="small"
            style={{ marginTop: 12, border: "1px solid #ccc", borderRadius: 4 }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body1">
                    {i18n.t("businessHours.holiday.name")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">
                    {i18n.t("businessHours.holiday.date")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1">
                    {i18n.t("businessHours.holiday.actions")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidays && holidays.length > 0
                ? holidays.map((holiday, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{holiday.name}</TableCell>
                      <TableCell align="center">
                        {format(parseISO(holiday.date), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleEditHoliday(holiday)}
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={() => handleOpenModalConfirm(holiday.id)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
              {holidays && holidays.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">
                      {i18n.t("businessHours.holiday.noHolidays")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BusinessHoursTab;
