import React, { useContext, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Title from "../../components/Title";
import { Chip, Tab, Tabs } from "@material-ui/core";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n.js";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainHeader from "../../components/MainHeader/index.js";
import { useSettingsContext } from "../../context/Setting/SettingsContext.js";
import AIAssistantsTab from "../../components/AIAssistantsTab/index.jsx";
import GeneralSettingsTab from "../../components/GeneralSettingsTab/index.jsx";
import SecurityTab from "../../components/SecurityTab/index.jsx";
import BusinessHoursTab from "../../components/BusinessHoursTab/index.jsx";

const useStyles = makeStyles((theme) => ({
  // root: {
  // 	display: "flex",
  // 	alignItems: "center",
  // 	padding: theme.spacing(8, 8, 3),
  // },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },

  tabs: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },

  aiAssistantsTab: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const { settings } = useSettingsContext();

  const [tabValue, setTabValue] = useState(0);

  const getBackEndVersion = () => {
    return localStorage.getItem("backendVersion") || "0.0.0";
  };

  const getFrontEndVersion = () => {
    return localStorage.getItem("frontendVersion") || "0.0.0";
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <MainHeader>
          <Title>{i18n.t("settings.title")}</Title>
        </MainHeader>

        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          className={classes.tabs}
        >
          <Tab label={i18n.t("settings.tabs.general")} {...a11yProps(0)} />
          <Tab
            label={i18n.t("settings.tabs.businessHours")}
            {...a11yProps(1)}
          />
          <Can
            role={user.profile}
            perform="settings:edit"
            yes={() =>
              React.cloneElement(
                <Tab label={i18n.t("settings.tabs.security")} />,
                {
                  ...a11yProps(2),
                  onClick: (event) => handleChangeTab(event, 2),
                }
              )
            }
          />
          <Can
            role={user.profile}
            perform="settings:edit"
            yes={() =>
              React.cloneElement(
                <Tab
                  label={
                    <div className={classes.aiAssistantsTab}>
                      {i18n.t("settings.tabs.aiAssistants")}
                      <Chip
                        label={i18n.t("AIAssistantsTab.beta")}
                        color="primary"
                        size="small"
                        style={{ marginLeft: "5px", fontSize: "10px" }}
                      />
                    </div>
                  }
                />,
                {
                  ...a11yProps(3),
                  onClick: (event) => handleChangeTab(event, 3),
                }
              )
            }
          />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <GeneralSettingsTab settings={settings} user={user} />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <BusinessHoursTab />
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <SecurityTab settings={settings} user={user} />
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Can
            role={user.profile}
            perform={"settings:edit"}
            yes={() => <AIAssistantsTab settings={settings} />}
          />
        </TabPanel>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="body2">
            {i18n.t("settings.settings.version")} {getFrontEndVersion()}
          </Typography>

          <Typography variant="body2">
            {i18n.t("settings.settings.versionBackend")} {getBackEndVersion()}
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Settings;
