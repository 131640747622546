import React from "react";
import { getBackendUrl } from "../../config";

const ApiDocs = () => {
  const backendUrl = getBackendUrl();

  return (
    <iframe
      src={`${backendUrl}/api-docs`}
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="API Documentation"
    />
  );
};

export default ApiDocs;
