import {
  Button,
  Container,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import AutoFollowUpRuleModal from "../../components/AutoFollowUpRuleModal";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const AutoFollowUpRule = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [autoFollowUpRules, setAutoFollowUpRules] = useState([]);
  const [autoFollowUpRuleId, setAutoFollowUpRuleId] = useState(null);

  const fetchAutoFollowUpRules = useCallback(async () => {
    try {
      const { data } = await api.get("/auto-follow-up-rule");

      setAutoFollowUpRules(data?.autoFollowUpRules);
    } catch (error) {
      toastError(error);
    }
  }, []);

  useEffect(() => {
    fetchAutoFollowUpRules();
  }, [fetchAutoFollowUpRules, openModal]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditAutoFollowUpRule = (autoFollowUpRule) => {
    setAutoFollowUpRuleId(autoFollowUpRule.id);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setAutoFollowUpRuleId(null);
  };

  const handleDeleteAutoFollowUpRule = async (autoFollowUpRuleId) => {
    try {
      await api.delete(`/auto-follow-up-rule/${autoFollowUpRuleId}`);
      fetchAutoFollowUpRules();

      toast.success(i18n.t("AutoFollowUpRule.toasts.deleted"));
    } catch (error) {
      toastError(error);
    }
  };

  const handleStatusChange = async (event, autoFollowUpRule) => {
    const newStatus = event.target.checked ? "enabled" : "disabled";

    try {
      await api.put(`/auto-follow-up-rule/${autoFollowUpRule.id}`, {
        name: autoFollowUpRule.name,
        days: autoFollowUpRule.days,
        followUpMessage: autoFollowUpRule.followUpMessage,
        actions: autoFollowUpRule.actions || [],
        conditions: autoFollowUpRule.autoFollowUpConditions || [],
        status: newStatus,
      });

      toast.success(i18n.t("AutoFollowUpRule.toasts.statusChanged"));

      fetchAutoFollowUpRules();
    } catch (error) {
      toastError(error);
    }
  };

  const generateExplanation = (
    executionType,
    days,
    hours,
    minutes,
    months,
    years
  ) => {
    if (executionType === "daily") {
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return `${i18n.t("autoFollowUpRuleModal.explanation.daily", { time })}`;
    } else if (executionType === "nDaily") {
      const time = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return `${i18n.t("autoFollowUpRuleModal.explanation.nDaily", {
        days: days,
        time,
      })}`;
    } else if (executionType === "once") {
      const dateTime = new Date(years, months - 1, days, hours, minutes);

      const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
      const day = dateTime.getDate().toString().padStart(2, "0");
      const year = dateTime.getFullYear();
      const time = `${dateTime
        .getHours()
        .toString()
        .padStart(2, "0")}:${dateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      return `${i18n.t("autoFollowUpRuleModal.explanation.once", {
        dateTime: `${day} - ${month} - ${year} ${time}`,
      })}`;
    }
    return "";
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <AutoFollowUpRuleModal
        open={openModal}
        onClose={handleClose}
        autoFollowUpRuleId={autoFollowUpRuleId}
      />
      <MainHeader>
        <Title>{i18n.t("AutoFollowUpRule.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {i18n.t("AutoFollowUpRule.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("AutoFollowUpRule.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AutoFollowUpRule.table.excuteTime")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AutoFollowUpRule.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AutoFollowUpRule.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AutoFollowUpRule.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {autoFollowUpRules?.map((autoFollowUpRule) => (
                <TableRow key={autoFollowUpRule.id}>
                  <TableCell align="center">{autoFollowUpRule.name}</TableCell>
                  <TableCell align="center">
                    {generateExplanation(
                      autoFollowUpRule.executionType,
                      autoFollowUpRule.days,
                      autoFollowUpRule.hours,
                      autoFollowUpRule.minutes,
                      autoFollowUpRule.months,
                      autoFollowUpRule.years
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {autoFollowUpRule.message.length > 100
                      ? `${autoFollowUpRule.message.substring(0, 150)}...`
                      : autoFollowUpRule.message}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      color="primary"
                      size="small"
                      checked={autoFollowUpRule.status === "enabled"}
                      onChange={(e) => handleStatusChange(e, autoFollowUpRule)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleEditAutoFollowUpRule(autoFollowUpRule)
                      }
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() =>
                        handleDeleteAutoFollowUpRule(autoFollowUpRule.id)
                      }
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default AutoFollowUpRule;
