import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

import toastError from "../../errors/toastError";
import { usePermissionsContext } from "../../context/Permission/PermissionsContext";
import { useSettingsContext } from "../../context/Setting/SettingsContext";

import { parseISO, compareDesc } from "date-fns";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  loadMoreButton: {
    textAlign: "center",
    padding: "8px 0",
    cursor: "pointer",
    margin: "8px 0",
  }
}));

const sortByUpdatedAt = (a, b) => {
  const dateA = parseISO(a.updatedAt);
  const dateB = parseISO(b.updatedAt);
  return compareDesc(dateA, dateB);
};

const reducer = (state, action) => {
  let newState;

  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    newState = [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    newState = [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    newState = [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const { ticket, selectedQueueIds, selectedLabelIds } = action.payload;

    const filterByQueue = (ticket, selectedQueueIds) => {
      const hasQueues = ticket.queue && ticket.queueId;

      const matchesQueue = hasQueues
        ? selectedQueueIds.includes(ticket.queueId)
        : true;
      return matchesQueue;
    };

    const filterByLabel = (ticket, selectedLabelIds) => {
      // Check if [-1] is in selectedLabelIds for "no label" selection
      const includeNoLabel = selectedLabelIds.includes(-1);

      // Determine if the ticket has labels
      const hasLabels = ticket.labels && ticket.labels.length > 0;

      // If the ticket has labels, check if any of those labels are in the selectedLabelIds
      const matchesLabel = hasLabels
        ? ticket.labels.some((label) => selectedLabelIds.includes(label.id))
        : includeNoLabel; // If the ticket has no labels, it matches if "no label" is selected

      return matchesLabel;
    };

    const ticketMatchesFilters = (
      ticket,
      selectedQueueIds,
      selectedLabelIds
    ) => {
      return (
        filterByQueue(ticket, selectedQueueIds) &&
        filterByLabel(ticket, selectedLabelIds)
      );
    };

    if (ticketMatchesFilters(ticket, selectedQueueIds, selectedLabelIds)) {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        state.unshift(state.splice(ticketIndex, 1)[0]);
      } else {
        state.unshift(ticket);
      }

      newState = [...state];
    } else {
      return state;
    }
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    newState = [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    newState = [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  return newState.sort(sortByUpdatedAt);
};

const TicketsList = (props) => {
  const {
    status,
    searchParam,
    showAll,
    selectedQueueIds,
    selectedLabelIds,
    updateCount,
    style,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);

  const [linkProtectionEnabled, setLinkProtectionEnabled] =
    useState("disabled");
  const [disableHyperlink, setDisableHyperlink] = useState("disabled");
  const [maskLink, setMaskLink] = useState("disabled");

  const [loadingPinnedTickets, setLoadingPinnedTickets] = useState(false);

  const { permissions } = usePermissionsContext();
  const { settings } = useSettingsContext();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [
    status,
    searchParam,
    dispatch,
    showAll,
    selectedQueueIds,
    selectedLabelIds,
  ]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
    labelIds: JSON.stringify(selectedLabelIds),
  });

  useEffect(() => {
    if (!status && !searchParam) return;
    dispatch({
      type: "LOAD_TICKETS",
      payload: tickets,
    });
  }, [tickets]);

  useEffect(() => {
    const socket = openSocket();

    const shouldUpdateTicket = async (ticket) => {
      // showAll ||
      // !searchParam &&
      // (!ticket.userId || (user?.profile === "user" && ticket.userId === user?.id) || user?.profile === "admin") &&
      // (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

      if (searchParam) {
        return false;
      }

      if (user.profile !== "admin") {
        if (permissions?.user?.static) {
          if (status === "open" || status === "pending") {
            let applyShowAll =
              showAll === "true" &&
              permissions.user.static.includes("tickets-manager:showall");

            let applyViewAllNoQueue = permissions.user.static.includes(
              `tickets-manager:view-all-no-queue-in-${status === "open" ? "working" : "queue"
              }-tab`
            );
            let applyShowSameQueues = permissions.user.static.includes(
              `tickets-manager:show-same-queues-in-${status === "open" ? "working" : "queue"
              }-tab`
            );

            if (applyShowAll || applyViewAllNoQueue) {
              return true;
            } else if (applyShowSameQueues) {
              if (
                (ticket.queueId &&
                  selectedQueueIds.indexOf(ticket.queueId) > -1) ||
                ticket.userId === user.id
              ) {
                return true;
              }
            } else {
              return ticket.userId ? ticket.userId === user.id : false;
            }
          }
        }
      } else {
        return true;
      }
    };

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on("ticket", async (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (data.action === "update" && (await shouldUpdateTicket(data.ticket))) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on("appMessage", async (data) => {
      if (data.action === "create" && (await shouldUpdateTicket(data.ticket))) {
        dispatch({
          type: "UPDATE_TICKET_UNREAD_MESSAGES",
          payload: {
            ticket: data.ticket,
            selectedQueueIds,
            selectedLabelIds,
          },
        });
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, searchParam, showAll, user, selectedQueueIds, selectedLabelIds]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  useEffect(() => {
    const fetchLinkProtection = async () => {
      try {
        const linkProtectionEnabled =
          settings &&
          settings.length > 0 &&
          settings.find((item) => item.key === "linkProtectionEnabled")?.value;
        const disableHyperlink =
          settings &&
          settings.length > 0 &&
          settings.find((item) => item.key === "disableHyperlink")?.value;
        const maskLink =
          settings &&
          settings.length > 0 &&
          settings.find((item) => item.key === "maskLink")?.value;

        setDisableHyperlink(disableHyperlink);
        setMaskLink(maskLink);
        setLinkProtectionEnabled(linkProtectionEnabled);
      } catch (err) {
        toastError(err);
      }
    };

    fetchLinkProtection();
  }, []);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const sortTickets = (tickets) => {
    return tickets.sort((a, b) => {
      if (a.status === "open" && b.status === "open") {
        if (a.isPinned && !b.isPinned) return -1;
        if (!a.isPinned && b.isPinned) return 1;
      }
      return parseISO(b.updatedAt) - parseISO(a.updatedAt);
    });
  };

  const togglePinTicket = async (ticketId, status, isPinned) => {
    if (status !== "open") return; // Only allow pinning for open tickets
    setLoadingPinnedTickets(true);

    try {
      await api.put(`/tickets/${ticketId}`, {
        userId: user.id,
        isPinned
      });

      toast.success(i18n.t("ticketsList.pinSuccess"));
    } catch (error) {
      toastError(error);
    }

    setLoadingPinnedTickets(false);
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
      >
        <List style={{ paddingTop: 0 }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {sortTickets(ticketsList).map((ticket) => (
                <TicketListItem
                  ticket={ticket}
                  key={ticket.id}
                  linkProtectionEnabled={linkProtectionEnabled}
                  disableHyperlink={disableHyperlink}
                  maskLink={maskLink}
                  isPinned={
                    ticket.status === "open" && ticket.isPinned ? true : false
                  }
                  onTogglePin={() => togglePinTicket(ticket.id, ticket.status, !ticket.isPinned)}
                  loadingPinnedTickets={loadingPinnedTickets}
                  isGroup={ticket.contact.isGroup}
                />
              ))}

              {hasMore && (
                <Button
                  onClick={loadMore}
                  disabled={loading}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.loadMoreButton}
                >
                  {loading ? 'Loading...' : 'Load More'}
                </Button>
              )}
            </>
          )}
          {loading && ticketsList.length === 0 && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
