import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textActionButtons: {
    fontSize: 14,

    [theme.breakpoints.down("xs")]: {
      // mobile
      fontSize: 12,
    },
  },
}));

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
  const classes = useStyles();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const handleOpenTransferModal = (e) => {
    setTransferTicketModalOpen(true);
    handleClose();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const handleSetTicketUnread = () => {
    try {
      api.put(`/tickets/${ticket.id}`, {
        unreadMessages: true,
        userId: user.id
      });

      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        <span>
          <Can
            role={user.profile}
            perform="ticket-options:transferTicket"
            yes={() => (
              <MenuItem onClick={handleOpenTransferModal}>
                <Typography
                  variant="body2"
                  className={classes.textActionButtons}
                >
                  {i18n.t("ticketOptionsMenu.transfer")}
                </Typography>
              </MenuItem>
            )}
          />
        </span>
        <span>
          <Can
            role={user.profile}
            perform="ticket-options:deleteTicket"
            yes={() => (
              <MenuItem onClick={handleOpenConfirmationModal}>
                <Typography
                  variant="body2"
                  className={classes.textActionButtons}
                >
                  {i18n.t("ticketOptionsMenu.delete")}
                </Typography>
              </MenuItem>
            )}
          />
        </span>
        <span>
          <MenuItem onClick={handleSetTicketUnread}>
            <Typography variant="body2" className={classes.textActionButtons}>
              {i18n.t("ticketOptionsMenu.setUnread")}
            </Typography>
          </MenuItem>
        </span>
      </Menu>
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
          ticket.id
        } ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
          ticket.contact.name
        }?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        <Typography variant="body2" className={classes.textActionButtons}>
          {i18n.t("ticketOptionsMenu.confirmationModal.message")}
        </Typography>
      </ConfirmationModal>
      <TransferTicketModal
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
        ticketWhatsappId={ticket.whatsappId}
      />
    </>
  );
};

export default TicketOptionsMenu;
